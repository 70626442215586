// Generated by Framer (cd03546)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {JDGKNzHS2: {hover: true}, nFpdIv3H0: {hover: true}};

const cycleOrder = ["JDGKNzHS2", "nFpdIv3H0"];

const variantClassNames = {JDGKNzHS2: "framer-v-qlqbfg", nFpdIv3H0: "framer-v-9tcw56"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Black Button": "nFpdIv3H0", "White button": "JDGKNzHS2"}

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, CZBwNEHyj: link ?? props.CZBwNEHyj, o8zK7_KhO: title ?? props.o8zK7_KhO ?? "Contact", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "JDGKNzHS2"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, o8zK7_KhO, CZBwNEHyj, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "JDGKNzHS2", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-OBVmZ", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={CZBwNEHyj} openInNewTab={false} smoothScroll><motion.a {...restProps} className={`${cx("framer-qlqbfg", className)} framer-jvp443`} data-framer-name={"White button"} layoutDependency={layoutDependency} layoutId={"JDGKNzHS2"} ref={ref} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}} variants={{"JDGKNzHS2-hover": {backgroundColor: "var(--token-69d0d919-5106-45a3-9073-8a95b5ccacb9, rgb(255, 90, 0))"}, "nFpdIv3H0-hover": {backgroundColor: "var(--token-69d0d919-5106-45a3-9073-8a95b5ccacb9, rgb(255, 90, 0))"}, nFpdIv3H0: {backgroundColor: "var(--token-883132e0-45d6-4b2b-bb30-29dc404e311e, rgb(29, 29, 31))"}}} {...addPropertyOverrides({"JDGKNzHS2-hover": {"data-framer-name": undefined}, "nFpdIv3H0-hover": {"data-framer-name": undefined}, nFpdIv3H0: {"data-framer-name": "Black Button"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItNjAw", "--framer-font-size": "18px", "--framer-font-weight": "600", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Contact</motion.p></React.Fragment>} className={"framer-1m2ebc2"} fonts={["GF;Inter-600"]} layoutDependency={layoutDependency} layoutId={"wra3hAFYd"} style={{"--extracted-r6o4lv": "var(--token-69d0d919-5106-45a3-9073-8a95b5ccacb9, rgb(255, 90, 0)) ", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={o8zK7_KhO} variants={{"JDGKNzHS2-hover": {"--extracted-r6o4lv": "rgb(255, 255, 255)"}, nFpdIv3H0: {"--extracted-r6o4lv": "rgb(255, 255, 255)"}}} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-OBVmZ [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-OBVmZ .framer-jvp443 { display: block; }", ".framer-OBVmZ .framer-qlqbfg { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 12px 22px 12px 22px; position: relative; text-decoration: none; width: min-content; }", ".framer-OBVmZ .framer-1m2ebc2 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-OBVmZ .framer-v-qlqbfg .framer-qlqbfg, .framer-OBVmZ .framer-v-9tcw56 .framer-qlqbfg { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-OBVmZ .framer-qlqbfg { gap: 0px; } .framer-OBVmZ .framer-qlqbfg > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-OBVmZ .framer-qlqbfg > :first-child { margin-left: 0px; } .framer-OBVmZ .framer-qlqbfg > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 46
 * @framerIntrinsicWidth 114
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"nFpdIv3H0":{"layout":["auto","auto"]},"DHFAfkrCb":{"layout":["auto","auto"]},"HR_erH6Gm":{"layout":["auto","auto"]}}}
 * @framerVariables {"o8zK7_KhO":"title","CZBwNEHyj":"link"}
 * @framerImmutableVariables false
 */
const FramerNNeq48sUh: React.ComponentType<Props> = withCSS(Component, css, "framer-OBVmZ") as typeof Component;
export default FramerNNeq48sUh;

FramerNNeq48sUh.displayName = "Button";

FramerNNeq48sUh.defaultProps = {height: 46, width: 114};

addPropertyControls(FramerNNeq48sUh, {variant: {options: ["JDGKNzHS2", "nFpdIv3H0"], optionTitles: ["White button", "Black Button"], title: "Variant", type: ControlType.Enum}, o8zK7_KhO: {defaultValue: "Contact", displayTextArea: false, title: "Title", type: ControlType.String}, CZBwNEHyj: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerNNeq48sUh, [{family: "Inter", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/NNeq48sUh:default", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf", weight: "600"}])